var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card border-light"},[_c('div',{staticClass:"card-header text-center"},[_c('h5',{staticClass:"m-0"},[_c('span',{staticClass:"pr-2 text-white regular"},[_vm._v("SUCURSALES NO ASIGNADAS ")]),_c('v-badge',{staticClass:"regular",attrs:{"color":"primary","content":_vm.searchBranch[0].length ? _vm.searchBranch[0].length : '0'}})],1)]),[_c('v-expansion-panels',{attrs:{"accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('i',{staticClass:"feather icon-terminal mr-0"}),_c('strong',[_vm._v("Búsqueda avanzada")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card-body p-0"},[_vm._v(" En este apartado se ingresa los clues de las sucursales que se requieren asignar al usuario registrado o que se necesita modificar. "),_c('span',{staticClass:"hand mr-2",on:{"click":function($event){return _vm.infoDetail()}}},[_c('i',{staticClass:"feather icon-help-circle"})])])]),_c('div',{staticClass:"col-md-12 pb-0"},[_c('v-textarea',{staticClass:"br",attrs:{"rows":"3","outlined":"","color":_vm.searchBranch[1] == undefined || _vm.clues == ''
                    ? '#1976d2'
                    : _vm.searchBranch[1].length == 0
                    ? '#037550'
                    : '#fb6340',"label":"Clues","append-icon":_vm.searchBranch[1] == undefined || _vm.clues == ''
                    ? ''
                    : _vm.searchBranch[1].length == 0
                    ? 'feather icon-check-circle'
                    : 'feather icon-alert-triangle'},on:{"click:append":function($event){_vm.searchBranch[1] == undefined || _vm.clues == ''
                    ? null
                    : _vm.searchBranch[1].length == 0
                    ? null
                    : _vm.hideModal()}},model:{value:(_vm.clues),callback:function ($$v) {_vm.clues=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"clues"}})],1)])])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('i',{staticClass:"feather icon-filter mr-0"}),_c('strong',[_vm._v(" Filtros de búsqueda")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"},[_c('v-text-field',{staticClass:"br",attrs:{"label":"Buscar...","oninput":"this.value = this.value.toUpperCase()","outlined":"","dense":"","append-icon":"feather icon-search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.jurisdictions,"chips":"","outlined":"","dense":"","deletable-chips":"","multiple":"","clearable":"","item-value":"value","hide-details":"auto","clear-icon":"feather icon-x-circle","append-icon":"feather icon-chevron-down","label":"Jurisdicción"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":"#4caf50 ","close":"","dense":"","small":"","close-icon":"feather icon-x"},on:{"click:close":function($event){return _vm.jurisdictionsValue.splice(index, 1)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.jurisdictionsValue),callback:function ($$v) {_vm.jurisdictionsValue=$$v},expression:"jurisdictionsValue"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.typeBranch,"chips":"","deletable-chips":"","multiple":"","outlined":"","dense":"","clearable":"","hide-details":"auto","item-value":"value","append-icon":"feather icon-chevron-down","clear-icon":"feather icon-x-circle","label":"Tipo de Centro"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":"#4caf50","close":"","small":"","close-icon":"feather icon-x"},on:{"click:close":function($event){return _vm.typeBranchValues.splice(index, 1)}}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}]),model:{value:(_vm.typeBranchValues),callback:function ($$v) {_vm.typeBranchValues=$$v},expression:"typeBranchValues"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.provinces,"chips":"","outlined":"","dense":"","deletable-chips":"","multiple":"","clearable":"","item-value":"value","hide-details":"auto","clear-icon":"feather icon-x-circle","append-icon":"feather icon-chevron-down","label":"Municipio"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":"#4caf50 ","close":"","small":"","close-icon":"feather icon-x"},on:{"click:close":function($event){return _vm.provincesValue.splice(index, 1)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.provincesValue),callback:function ($$v) {_vm.provincesValue=$$v},expression:"provincesValue"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.zones,"chips":"","outlined":"","dense":"","deletable-chips":"","multiple":"","clearable":"","item-value":"value","hide-details":"auto","clear-icon":"feather icon-x-circle","append-icon":"feather icon-chevron-down","label":"Zona"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":"#4caf50 ","close":"","small":"","close-icon":"feather icon-x"},on:{"click:close":function($event){return _vm.zonesValue.splice(index, 1)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.zonesValue),callback:function ($$v) {_vm.zonesValue=$$v},expression:"zonesValue"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.status,"outlined":"","dense":"","clearable":"","item-value":"value","color":_vm.statusValue == 0
                    ? '#ff3f3f'
                    : _vm.statusValue == undefined
                    ? '#1976d2'
                    : '#4caf50',"hide-details":"auto","clear-icon":"feather icon-x-circle","append-icon":"feather icon-chevron-down","label":"Habilitadas / Deshabilitadas"},model:{value:(_vm.statusValue),callback:function ($$v) {_vm.statusValue=$$v},expression:"statusValue"}})],1)])])],1)],1)],_c('v-divider',{staticClass:"mt-0 mb-0"}),(_vm.searchBranch[0].length == 0)?_c('div',[_c('div',{staticClass:"col-md-6 col-lg-12 col-xl-12 text-center"},[_c('b-card-title',[_c('i',{class:_vm.search != ''
              ? 'feather icon-search mr-2 fa-lg'
              : 'feather icon-file mr-2 fa-lg'})]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.search != "" ? "Sin resultados" : "Sin sucursales para asignar")+" ")])],1)]):_c('div',[_c('div',{staticClass:"scroll-list"},[_c('v-virtual-scroll',{attrs:{"items":_vm.searchBranch[0],"item-height":55,"height":"490"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":item.status ? '#70c16e' : '#ff7575'}},[_c('v-avatar',{staticClass:"white--text",attrs:{"size":"56"}},[_c('i',{class:item.status ? 'feather icon-check' : 'feather icon-x'})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.branchOfficeKey))])],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"#fff","fab":"","small":"","depressed":""},on:{"click":function($event){return _vm.addAssignedBranches(item)}}},[_c('v-icon',{attrs:{"color":"#4ba948"}},[_vm._v(" feather icon-plus-circle ")])],1)],1)],1)]}}])})],1)]),_c('v-divider',{staticClass:"mt-0 mb-3"}),_c('div',{staticClass:"card-footer border-0 "},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pt-0 text-left"},[_c('button',{staticClass:"btn btn-outline-success br",attrs:{"type":"button","disabled":_vm.searchBranch[0].length == 0},on:{"click":function($event){return _vm.addAllFilter()}}},[_c('i',{staticClass:"feather icon-plus-circle"}),_vm._v(" "+_vm._s(_vm.$tc("configuration.title_button_plus", _vm.searchBranch[0].length, { n: _vm.searchBranch[0].length, }))+" ")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }