<template>
  <div class="card border-light">
    <div class="card-header text-center">
      <h5 class="m-0">
        <span class="pr-2 text-white regular">SUCURSALES ASIGNADAS </span>
        <v-badge
          color="primary"
          class="regular"
          :content="searchBranch[0].length ? searchBranch[0].length : '0'"
        ></v-badge>
      </h5>
    </div>
    <template>
      <v-expansion-panels v-model="panel" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><i class="feather icon-terminal mr-0"></i
            ><strong>Búsqueda avanzada</strong></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  Para garantizar la precisión de los porcentajes en el Tablero
                  de Control de Abasto, se considerarán únicamente las
                  sucursales asignadas. Por lo tanto, cualquier otra sucursal
                  que no haya sido asignada no será incluida en el cálculo.

                  <span class="hand mr-2" @click="infoDetail()">
                    <i class="feather icon-help-circle"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-12 pb-0">
                <v-textarea
                  class="br"
                  outlined
                  :color="
                    searchBranch[1] == undefined || clues == ''
                      ? '#1976d2'
                      : searchBranch[1].length == 0
                      ? '#037550'
                      : '#fb6340'
                  "
                  label="Clues"
                  rows="3"
                  :append-icon="
                    searchBranch[1] == undefined || clues == ''
                      ? ''
                      : searchBranch[1].length == 0
                      ? 'feather icon-check-circle'
                      : 'feather icon-alert-triangle'
                  "
                  @click:append="
                    searchBranch[1] == undefined || clues == ''
                      ? null
                      : searchBranch[1].length == 0
                      ? null
                      : hideModal()
                  "
                  persistent-hint
                  v-model.trim="clues"
                ></v-textarea>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="box-shadow">
          <v-expansion-panel-header
            ><i class="feather icon-filter mr-0"></i>
            <strong> Filtros de búsqueda</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                <v-text-field
                  v-model="search"
                  label="Buscar..."
                  oninput="this.value = this.value.toUpperCase()"
                  outlined
                  dense
                  class="br"
                  append-icon="feather icon-search"
                  hide-details
                ></v-text-field>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                <v-autocomplete
                  v-model="jurisdictionsValue"
                  :items="jurisdictions"
                  chips
                  class="br"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  clearable
                  item-value="value"
                  hide-details="auto"
                  clear-icon="feather icon-x-circle"
                  append-icon="feather icon-chevron-down"
                  label="Jurisdicción"
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      color="#4caf50 "
                      close
                      class="chip"
                      dense
                      small
                      close-icon="feather icon-x"
                      @click:close="jurisdictionsValue.splice(index, 1)"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0">
                <v-autocomplete
                  v-model="typeBranchValues"
                  :items="typeBranch"
                  chips
                  deletable-chips
                  multiple
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  item-value="value"
                  class="br"
                  append-icon="feather icon-chevron-down"
                  clear-icon="feather icon-x-circle"
                  label="Tipo de Centro"
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      color="#4caf50"
                      close
                      small
                      class="chip"
                      close-icon="feather icon-x"
                      @click:close="typeBranchValues.splice(index, 1)"
                    >
                      {{ item.text.toUpperCase() }}
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>

              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0">
                <v-autocomplete
                  v-model="provincesValue"
                  :items="provinces"
                  chips
                  class="br"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  clearable
                  item-value="value"
                  hide-details="auto"
                  clear-icon="feather icon-x-circle"
                  append-icon="feather icon-chevron-down"
                  label="Municipio"
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      color="#4caf50 "
                      close
                      class="chip"
                      small
                      close-icon="feather icon-x"
                      @click:close="provincesValue.splice(index, 1)"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <v-autocomplete
                  v-model="zonesValue"
                  :items="zones"
                  chips
                  class="br"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  clearable
                  item-value="value"
                  hide-details="auto"
                  clear-icon="feather icon-x-circle"
                  append-icon="feather icon-chevron-down"
                  label="Zona"
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      color="#4caf50 "
                      close
                      class="chip"
                      small
                      close-icon="feather icon-x"
                      @click:close="zonesValue.splice(index, 1)"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <v-autocomplete
                  v-model="statusValue"
                  :items="status"
                  class="br"
                  outlined
                  dense
                  clearable
                  item-value="value"
                  :color="
                    statusValue == 0
                      ? '#ff3f3f'
                      : statusValue == undefined
                      ? '#1976d2'
                      : '#4caf50'
                  "
                  hide-details="auto"
                  clear-icon="feather icon-x-circle"
                  append-icon="feather icon-chevron-down"
                  label="Habilitadas / Deshabilitadas"
                >
                </v-autocomplete>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-divider class="mt-0 mb-0"></v-divider>

    <div v-if="searchBranch[0].length == 0">
      <div class="col-md-6 col-lg-12 col-xl-12 text-center">
        <b-card-title
          ><i
            v-bind:class="
              search != ''
                ? 'feather icon-search mr-2 fa-lg'
                : 'feather icon-file mr-2 fa-lg'
            "
          ></i
        ></b-card-title>
        <b-card-text>
          {{ search != "" ? "Sin resultados" : "Sin sucursales asignadas" }}
        </b-card-text>
      </div>
    </div>
    <div v-else>
      <div class="scroll-list">
        <v-virtual-scroll
          class=""
          :items="searchBranch[0]"
          :item-height="50"
          height="490"
        >
          <template v-slot:default="{ item }">
            <v-list-item>
              <v-list-item-avatar :color="item.status ? '#70c16e' : '#ff7575'">
                <v-avatar size="56" class="white--text">
                  <i
                    :class="
                      item.status ? 'feather icon-check' : 'feather icon-x'
                    "
                  ></i>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.branchOfficeKey
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  @click="deleteAssignedBranches(item)"
                  class="mx-2"
                  color="#fff"
                  fab
                  small
                  depressed
                >
                  <v-icon :color="'#ff4c4c'">
                    feather icon-minus-circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
    </div>

    <v-divider class="mt-0 mb-3"></v-divider>
    <div class="col-md-12 pt-0 text-right">
      <button
        type="button"
        @click="deleteAllFilter()"
        class="btn btn-outline-danger br"
        :disabled="searchBranch[0].length == 0"
      >
        <i class="feather icon-minus-circle"></i>
        {{
          $tc("configuration.title_button_minus", searchBranch[0].length, {
            n: searchBranch[0].length,
          })
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { SwalConfirm } from "@/core/SwalAlert";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    listBranch: {
      required: true,
      type: Array,
    },
    jurisdictions: {
      required: true,
      type: Array,
    },
    typeBranch: {
      required: true,
      type: Array,
    },
    provinces: {
      required: true,
      type: Array,
    },
    zones: {
      required: true,
      type: Array,
    },
    status: {
      required: true,
      type: Array,
    },
    deleteAssignedBranches: Function,
  },
  data() {
    return {
      typeBranchValues: [],
      jurisdictionsValue: [],
      provincesValue: [],
      zonesValue: [],
      statusValue: null,
      search: "",
      clues: "",
      panel: 1,
    };
  },
  computed: {
    searchBranch() {
      if (this.panel === 0) {
        if (this.clues.trim() === "") {
          return [this.listBranch];
        }
        const listClues = this.clues.split(/\s*,\s*/);
        const filteredList = this.listBranch.filter((item) => {
          return listClues.includes(item.branchOfficeKey);
        });

        const notIncluded = listClues.filter((clue) => {
          return !this.listBranch.some((item) => {
            return item.branchOfficeKey === clue;
          });
        });
        return [filteredList, notIncluded];
      } else if (this.panel === 1) {
        const filters = {
          typeBranchValues: this.typeBranchValues,
          jurisdictionsValue: this.jurisdictionsValue,
          provincesValue: this.provincesValue,
          zonesValue: this.zonesValue,
          statusValue: this.statusValue,
          search: this.search
        };
        const filteredList = this.$UtilFront.applyFilters(
          this.listBranch,
          filters
        );
        return [filteredList];
      } else {
        return [this.listBranch];
      }
    },
  },
  methods: {
    async hideModal() {
      let tableHTML = "";
      this.searchBranch[1].map((res) => {
        tableHTML = tableHTML + "<tr><td>" + res + "</td></tr>";
      });
      const { isConfirmed } = await SwalConfirm.fire({
        html:
          "<table class='table table-striped' responsive style='width:100%'><tr><th>Sucursales no encotradas</th> </tr>" +
          tableHTML +
          "</table> ",
        title: "",
        icon: "",
        confirmButtonText:
          '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
        showCancelButton: false,
      });
      if (!isConfirmed) {
        return;
      }
    },
    reset() {
      this.clues = "";
      this.typeBranchValues = this.jurisdictionsValue = [];
      this.search = "";
    },
    async infoDetail() {
      let tableHTML =
        '<table> <thead> <tr> <th scope="col">Formatos correctos</th> </tr> </thead> <tbody> <tr> <td> <div class="card-subtitle">  </div> <div class="row">  <div class="col-sm-12 pt-0"> <br /> <strong>Lineal</strong> <br />JCSSA00398606, JCSSA00199008 </div> <div class="col-sm-12 pt-0"> <strong> No linea:</strong> <br /> JCSSA00398606, <br /> JCSSA00398606 </div> </div> </tbody> </table>';
      const { isConfirmed } = await SwalConfirm.fire({
        html: tableHTML,
        title: "",
        icon: "",
        confirmButtonText:
          '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
        showCancelButton: false,
      });
      if (!isConfirmed) {
        return;
      }
    },
    /**
     * Envia todas las sucursales para desasignar, solo toma en cuenta las que se encuentran filtradas
     * @method
     */
    async deleteAllFilter() {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html:
          "<div>Se desasignaran " +
          "<strong>" +
          this.searchBranch[0].length +
          "</strong> sucursales.  </div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.searchBranch[0].map((element) => {
        this.deleteAssignedBranches(element);
      });
    },
  },
  watch: {
    panel() {
      if (this.panel == 0) {
        this.typeBranchValues = this.jurisdictionsValue = [];
        this.search = "";
      }
      if (this.panel == 1) {
        this.clues = "";
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style scoped>
.chip {
  margin: 2px !important;
  margin-top: 4px !important;
}
.card {
  border-radius: 16px !important;
}

.card-header {
  background-color: #263a5b;
  border-radius: 12px;
  padding: 12px;
  border: none;
  margin: 4px;
}
.box-shadow {
  border-bottom: 1px solid #d4d8de;
  padding: 0px;
}

.v-expansion-panel:before {
  box-shadow: none;
  border-radius: 0px !important;
}

.v-expansion-panel {
  border: 0px solid #d4d8de !important;
  border-top-right-radius: 1px solid #d4d8de !important;

  border-bottom: none;
}

.v-expansion-panels > :first-child {
  border-top-left-radius: none;
  border-top-right-radius: none;
  border-bottom: none;
}

.v-expansion-panels {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 0;
}
</style>

<style scoped lang="scss">
::v-deep {
  .scroll-list {
    /* Estilos barra (thumb) de scroll */
    ::-webkit-scrollbar-thumb {
      background: #adb5bd;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: #ccc;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }
    /* Estilos track de scroll */
    ::-webkit-scrollbar-track {
      background: #dee2e6;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-track:hover,
    ::-webkit-scrollbar-track:active {
      background: #dee2e6;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0 10px 16px;
  }
}
</style>
