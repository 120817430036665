<template>
  <div>
    <div class="row">
      <AlertMessageComponent ref="alertMessageComponent" />

      <div class="col-md-12 pt-0">
        <div class="row">
          <div class="col-md-6">
            <BranchesToBeAssignedComponent
              ref="to_assing"
              :addAssignedBranches="addAssignedBranches"
              :typeBranch="typeBranch"
              :jurisdictions="jurisdictions"
              :listBranch="branchOfficesList"
              :provinces="provinces"
              :zones="zones"
              :status="status"
            />
          </div>
          <div class="col-md-6">
            <AssignedBranchesComponent
              ref="assign"
              :typeBranch="typeBranch"
              :jurisdictions="jurisdictions"
              :deleteAssignedBranches="deleteAssignedBranches"
              :listBranch="branchOfficesAssing"
              :provinces="provinces"
              :zones="zones"
              :status="status"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @component BranchOfficeCommon
 * @desc Este es el componente de BranchOfficeCommon es el que muestra el detalle del usuario
 * @author Jesus Teran
 */
import { ConfigurationRequest } from "@/core/request";
import { UtilFront } from "@/core";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AssignedBranchesComponent from "@/common/branchOffice/AssignedBranchesComponent";
import BranchesToBeAssignedComponent from "@/common/branchOffice/BranchesToBeAssignedComponent";

import AlertMessageComponent from "@/common/message/AlertMessageComponent";

export default {
  props: {
    userListBranchOffice: Array,
    branchOffice: Object,
    showAction: Function,
    changeAction: Function,
    listAll: Function,
    user_modules: {
      default: false,
    },
    main: Object,
  },
  data() {
    return {
      searchBranch: "",
      sending: false,

      branchOfficesList: [],
      branchOfficesAssing: [],

      /** * @member {Array} listDeleteBranchOffices La lista de las sucursales eliminasdas, de las sucursales asignadas. */
      /** * @member {Array} listAddBranchOffices Guarda la lista de las sucursales asignadas al usuario */
      listAddBranchOffices: [],
      listDeleteBranchOffices: [],

      loading: false,
      visible: true,
      typeBranch: [],
      jurisdictions: [],
      provinces: [],
      zones: [],
      status: [
        {
          text: "Habilitadas",
          value: 1,
        },
        {
          text: "Deshabilitadas",
          value: 0,
        },
      ],
    };
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    /**
     * Agrega a la lista de las sucursales asignadas
     * @method
     * @param {Object} branchOffice - La sucursal que se desea asignar
     */
    async addAssignedBranches(branchOffice) {
      this.listAddBranchOffices.push(branchOffice);
      this.branchOfficesAssing.push(branchOffice);
      this.listDeleteBranchOffices = this.listDeleteBranchOffices.filter(
        (branch) => branch.idBranchOffice !== branchOffice.idBranchOffice
      );
      this.branchOfficesList = this.branchOfficesList.filter(
        (branch) => branch.idBranchOffice !== branchOffice.idBranchOffice
      );
      if (this.user_modules)
        this.main.mainBranchOffice = this.branchOfficesAssing;
    },
    /**
     * Elimina sucursales asignadas y las pasa a la lista de sucursales para asignar.
     * @method
     * @param {Object} branchOffice - La sucursal que se desea eliminar
     */
    deleteAssignedBranches(branchOffice) {
      this.branchOfficesList.push(branchOffice);
      this.listDeleteBranchOffices.push(branchOffice);
      this.branchOfficesAssing = this.branchOfficesAssing.filter(
        (branch) => branch.idBranchOffice !== branchOffice.idBranchOffice
      );
      this.listAddBranchOffices = this.listAddBranchOffices.filter(
        (branch) => branch.idBranchOffice !== branchOffice.idBranchOffice
      );
      if (this.user_modules)
        this.main.mainBranchOffice = this.branchOfficesAssing;
    },
    /**
     * Obtiene la sucursales que tiene asignadas el usuario para colocar el la lista de asignadas.
     * @method
     * @param {Array} userListBranchOffice - Lista de las sucursales asignadas al usuario
     */
    async assingBranchOffices(userListBranchOffice) {
      this.branchOfficesAssing = [];
      userListBranchOffice.map((branchOfficesDelete) => {
        this.branchOfficesAssing.push(
          this.branchOfficesList.filter(
            (branchOffice) => branchOffice.idBranchOffice == branchOfficesDelete
          )[0]
        );
      });
      if (this.user_modules)
        this.main.mainBranchOffice = this.branchOfficesAssing;
    },
    /**
     * Elimina de la lista de sucursales, las que el usuario ya tiene asignadas.
     * @method
     * @param {Array} userListBranchOffice - Lista de las sucursales asignadas al usuario
     */
    async deleleBranchOfficesToAssing(userListBranchOffice) {
      userListBranchOffice.map((branchOfficesDelete) => {
        this.branchOfficesList = this.branchOfficesList.filter(
          (branch) => branch.idBranchOffice != branchOfficesDelete
        );
      });
    },
    /**
     * Devuelve un Objecto con la lista de sucursales asignada y eliminadas.
     * @method
     * @returns {Object} con las lista de Array de las sucursales asignadas (branchOfficesAdd) y las eliminadas (branchOfficesDelete)
     **/
    async getListBranchOfficeUser() {
      let branchUser = {
        branchOfficesAdd: [],
        branchOfficesDelete: [],
      };
      branchUser.branchOfficesAdd = await this.clearListAdd(
        this.userListBranchOffice
      );
      branchUser.branchOfficesAdd = branchUser.branchOfficesAdd.map((value) => {
        return value.idBranchOffice.toString();
      });
      if (this.userListBranchOffice) {
        branchUser.branchOfficesDelete = await this.clearListDelete(
          this.userListBranchOffice
        );
        branchUser.branchOfficesDelete = branchUser.branchOfficesDelete.map(
          (value) => {
            return value.idBranchOffice.toString();
          }
        );
      }
      if (this.userListBranchOffice == undefined) {
        if (branchUser.branchOfficesAdd.length == 0) {
          if (!this.user_modules) this.alert("error", "Error_User_0100");
          return undefined;
        }
      } else if (this.userListBranchOffice != undefined) {
        if (
          (branchUser.branchOfficesAdd.length == 0 &&
            branchUser.branchOfficesDelete.length == 0) ||
          (this.userListBranchOffice.length ==
            branchUser.branchOfficesDelete.length &&
            branchUser.branchOfficesAdd.length == 0)
        ) {
          if (!this.user_modules) this.alert("error", "Error_User_0100");
          return undefined;
        }
      }
      return branchUser;
    },
    /**
     * Limpia la lista de las sucursales asignadas sin tomar en cuenta las que ya se encuentra asignadas.
     * @method
     * @returns {Array} Lista de sucursales asignadas
     **/
    async clearListAdd(userListBranchOffice) {
      let listAdd = this.listAddBranchOffices;
      if (userListBranchOffice) {
        userListBranchOffice.map((branchOfficesAdd) => {
          listAdd = listAdd.filter(
            (branchOffice) => branchOffice.idBranchOffice != branchOfficesAdd
          );
        });
      }
      return listAdd;
    },
    /**
     * Limpia la lista de las sucursales eliminas tomando en cuenta solo las que ya se encontraban asignadas
     * @method
     * @returns {Array} Lista de sucursales eliminadas
     **/
    async clearListDelete(userListBranchOffice) {
      let listDelete = [];
      userListBranchOffice.map((branchOfficesDelete) => {
        listDelete.push(
          this.listDeleteBranchOffices.filter(
            (branchOffice) => branchOffice.idBranchOffice == branchOfficesDelete
          )[0]
        );
      });
      listDelete = listDelete.filter((value) => {
        return value != undefined;
      });
      return listDelete;
    },
    async reloadComponentUpdates() {
      if (this.userListBranchOffice) {
        await this.assingBranchOffices(this.userListBranchOffice);
        await this.deleleBranchOfficesToAssing(this.userListBranchOffice);
      }
    },
    /**
     * It gets all the branch offices, jurisdictions, types of branch, zones and provinces.
     * @method
     */
    async getAllFindBranchOffice() {
      this.loading = UtilFront.getLoadding();
      await ConfigurationRequest.getAllFindBranchOffice()
        .then((res) => {
          let { data } = res;
          if (data) {
            this.branchOfficesList = data.branchOffices;
            this.jurisdictions = data.jurisdictions;
            this.typeBranch = data.typesBranch;
            this.zones = data.zones;
            this.provinces = data.provinces;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
  async created() {
    await this.getAllFindBranchOffice();
    if (this.userListBranchOffice) {
      await this.assingBranchOffices(this.userListBranchOffice);
      await this.deleleBranchOfficesToAssing(this.userListBranchOffice);
    }
  },
  /**
   * @description Componenetes utilizado en la vista principal del modulo global para agregar y eliminar sucursales
   * @prop {Components} BranchesToBeAssignedComponent {@link component:BranchesToBeAssignedComponent} - Este es el componente muestra la sucursales para asignar
   * @prop {Components} AssignedBranchesComponent {@link component:AssignedBranchesComponent} - Este componente para mostrar la sucursales asignadas o eliminar sucursales asignadas
   */
  components: {
    ValidationProvider,
    ValidationObserver,
    AssignedBranchesComponent,
    BranchesToBeAssignedComponent,
    AlertMessageComponent,
  },
};
</script>

<style>
.theme--light.v-data-table > .v-data-table__wrapper {
  max-height: 520px;
}

.v-expansion-panel:before {
  box-shadow: none;
  border-radius: 0px;
}

.v-expansion-panel {
  border: 1px solid #d4d8de;
}

.v-expansion-panels > :first-child {
  border-top-left-radius: none;
  border-top-right-radius: none;
  border-bottom: none;
}

.v-expansion-panels {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 0;
}
</style>
